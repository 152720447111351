.whatsapp-chat-screen-wrapper {
  background-color: #faf6f4;
}

.whatsapp-chat-screen {
  display: flex;
  align-items: start;
  background-color: #faf6f4;
  min-height: 300px;
}

.chat-bubble {
  position: relative;
  max-width: 35ch;
  min-width: 35ch;
  background-color: #dcf8c6;
  padding: 10px 15px;
  border-radius: 15px;
  border-top-right-radius: 0;
  margin-top: 10px;
  color: #000;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.chat-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 20px solid #dcf8c6;
  border-right: 20px solid transparent;
}

.chat-bubble-editing-wrapper {
  max-width: 30ch;
  min-width: 30ch;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.chat-bubble-editing {
  padding: 0px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  width: 100%;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
}

.chat-bubble-editing:focus {
  outline: none;
}

.chat-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.time-text {
  font-size: 10px;
  color: gray;
  margin-right: 5px;
}

.double-tick {
  display: flex;
}

.tick-icon {
  color: #a7b0b4;
}
