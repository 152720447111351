.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  background-color: #202c33;
  border-radius: 10px;
  z-index: 99999999;
  isolation: isolate;
}

.minimized {
  width: 250px;
}

.chat-messages {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  background-image: url("https://img.freepik.com/premium-photo/dark-wooden-floor-background_485089-378.jpg?semt=ais_hybrid");
  background-position: center;
  background-repeat: repeat-y;
}

.message {
  margin: 5px 0;
}

.chat-input-container {
  display: flex;
  gap: 10px;
  align-items: start;
}

.chat-input {
  flex: 1;
  pointer-events: auto;
  max-height: 150px;
}
.receiver-chat-bubble {
  position: relative;
  max-width: 30ch;
  background-color: #005c4b; /* Light green background */
  padding: 10px 15px;
  border-radius: 15px;
  border-top-right-radius: 0;
  margin-top: 10px;
  color: #ffffff;
  font-size: 13px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.receiver-chat-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 15px solid #005c4b;
  border-right: 15px solid transparent;
}
.rtl-receiver-chat-bubble {
  position: relative;
  max-width: 30ch;
  background-color: #005c4b;
  padding: 10px 15px;
  border-radius: 15px;
  border-top-left-radius: 0;
  margin-top: 10px;
  color: #ffffff;
  font-size: 13px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.rtl-receiver-chat-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: 0;
  height: 0;
  border-right: 15px solid #005c4b;
  border-bottom: 15px solid transparent;
}

.sender-chat-bubble {
  position: relative;
  max-width: 30ch;
  background-color: #41515b;
  padding: 10px 15px;
  border-radius: 15px;
  border-top-left-radius: 0;
  margin-top: 10px;
  color: #ffffff;
  font-size: 13px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.sender-chat-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: 0;
  height: 0;
  border-right: 15px solid #41515b;
  border-bottom: 15px solid transparent;
}
.rtl-sender-chat-bubble {
  position: relative;
  max-width: 30ch;
  background-color: #41515b; /* Light green background */
  padding: 10px 15px;
  border-radius: 15px;
  border-top-right-radius: 0;
  margin-top: 10px;
  color: #ffffff;
  font-size: 13px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.rtl-sender-chat-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 15px solid #41515b;
  border-right: 15px solid transparent;
}

.whatsapp-chat-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.whatsapp-chat-time-text {
  font-size: 11px;
  color: #a4a4a4;
  margin-right: 5px;
}

.whatsapp-chat-icon {
  font-size: 14px;
  color: #a4a4a4;
}

.notification {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: green;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
