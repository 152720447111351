.report-template-title {
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.2s ease;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    border: 1px solid gray;
  }

  &:focus {
    outline: none;
  }
}
