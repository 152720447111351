.bar-container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.25);
}

.bar-content,
.bar-content-fullscreen {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: 0.4s ease-in-out;

  @media screen and (min-width: 480px) {
    &:not(.bar-content-fullscreen) {
      width: 50%;
    }
  }
}

.bar-children {
  height: 100%;
  width: 100%;
  padding: 0.75rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}

.bar-visible {
  visibility: visible;
}

.bar-visible > div {
  top: 0%;
  right: 0%;
}

.bar-collapsed {
  visibility: collapse;
}

.bar-collapsed > div {
  top: 0%;
  right: -100%;
}
