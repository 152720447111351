.richtext-dialog-content {
  z-index: 999999 !important;
}

[data-radix-popper-content-wrapper] {
  z-index: 999999 !important;
}

#bar-body:has(.tiptap-container) {
  height: 102%;
  overflow: hidden;
}

.bar-children:has(.tiptap-container) {
  overflow: hidden;
  padding-top: 0.5rem;
}

.tiptap-container {
  display: flex;
  justify-content: center;
  padding: 16px;
  background: #f5f5f5;
  height: 105%;

  .reactjs-tiptap-editor {
    border-radius: 8px !important;
  }

  .richtext-relative:has(.ProseMirror) {
    height: calc(100vh - 10rem);
    overflow-y: auto;
    padding-bottom: 1rem;
  }

  .ProseMirror {
    max-width: 210mm; // A4 width
    width: 100%;
    min-height: 297mm !important; // A4 height
    margin: 16px auto;
    background: white;
    padding: 20mm;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  .richtext-outline {
    outline-width: 0px;
    border-radius: 8px;
  }
}
