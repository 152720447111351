@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400..900&display=swap");

$theme-colors: (
  primary: #d89e31,
  primary-light: #feecc90e,
  primary-extra-light: #fff8eb0e,
  primary-dark: #fbe3b494,
  light: #fafafa,
  light-gray: #f6f6f6d9,
  dark: #001840,
  secondary-dark: #dbdbdb,
  info: #3260a8,
  secondary-info: #0d6efd,
  secondary: #757575,
  success: #28a745,
  dark-green: #24963e,
  danger: #e60707,
  purple: rgb(156, 40, 147),
  danger-light: rgba(255, 212, 212, 0.693),
  warning: #f7cb73,
  gray: #f2f3f5,
  light-green: #c9fecb0e,
  yellow: #ffd600,
);

$border-radius: 0.25rem;
$list-group-active-bg: #d89d31;
$dropdown-link-active-bg: #d89d31;

html,
body,
#root {
  height: 100%;
  font-size: 14px;
}

html[dir="rtl"],
html[dir="rtl"] body,
html[dir="rtl"] #root {
  font-family: "Noto Sans Hebrew", sans-serif;
}

html[dir="ltr"],
html[dir="ltr"] body,
html[dir="ltr"] #root {
  font-family: "Poppins", sans-serif;
}

.hover {
  cursor: pointer;
}

.border-style {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.clock-text {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
}

.hover-light-text:hover {
  color: map-get($theme-colors, primary) !important;
  cursor: pointer;
}

.hover-light:hover {
  background-color: #feecc99a;
  cursor: pointer;
}

.hover-dark:hover {
  color: white;
  background-color: #918f8d;
  cursor: pointer;
  border-radius: 3px;
}

.outline-none {
  outline: none;
}

.dropdown-toggle.no-caret.btn::after {
  display: none !important;
}

.a {
  cursor: pointer;
  text-decoration: underline;
}

.left-bar {
  border-left: 10px solid #0c2336;
}

.smallest {
  font-size: 9px;
}

.small {
  font-size: 12px;
}

.smallFont {
  font-size: 12px;
}

.smaller {
  font-size: 11px;
}

.mid {
  font-size: 13px;
}

.large {
  font-size: 14px;
}

.xlarge {
  font-size: 16px;
}

.xxlarge {
  font-size: 18px;
}

.xxxlarge {
  font-size: 22px;
}
.xxxxlarge {
  font-size: 28px;
}
.tiny {
  font-size: 10px;
}

.xs {
  font-size: 9px;
}

.underline {
  text-decoration: underline;
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.fade-in-quick {
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hide-after::after {
  display: none !important;
}

table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  left: 0;
  background-color: inherit;
}

.background {
  background-color: greenyellow;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure it's above other content but below fixed elements like navbars */
  background-color: #fff; /* Or any color matching your design */
}

.calc-column {
  min-width: 300px;
  max-width: 300px;
}

.chart-container {
  height: 400px;
}

// Target screens smaller than 'md'
$md-breakpoint: map-get($grid-breakpoints, md);

@media (max-width: ($md-breakpoint - 1)) {
  table > tbody > tr > td:nth-child(1),
  table > thead > tr:nth-child(1) > th:nth-child(1) {
    width: 120px;
    max-width: 120px;
  }

  .calc-column {
    min-width: 175px;
    max-width: 175px;
  }

  .chart-container {
    height: 300px;
  }
}

.expand-collapse-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  color: $list-group-active-bg;
  cursor: pointer;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  color: rgb(0, 0, 0);
  background-color: #f1f1f1;
}

.dropdown-toggle.no-caret.btn::after {
  display: none !important;
}

.datepickerPopperClassName {
  z-index: 99999 !important;
}

.tagsinput {
  // Customize the container
  .react-tagsinput {
    background-color: #f0f0f0; // Your theme's background
    border-radius: 4px;
    border: 1px solid #ccc; // Your theme's border color
  }

  // Customize individual tags
  .react-tagsinput-tag {
    background-color: map-get($theme-colors, dark); // Your theme's tag color
    color: white;
    border-width: 0px;
    border-radius: $border-radius;
    margin-right: 2px;
    margin-left: 2px;
    padding: 2px 6px;
    margin-bottom: 0px;

    // Customize the remove tag button
    .react-tagsinput-remove {
      cursor: pointer;
      color: white;
      margin-left: 5px;
      text-decoration: none;
    }
  }

  // Customize the input field
  .react-tagsinput-input {
    border: none;
    margin: 0;
    padding: 7px;
    background-color: transparent;
    color: #333; // Your theme's text color
  }
}

.truncate {
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Hides text overflowing the container */
  text-overflow: ellipsis; /* Adds an ellipsis to the end of the text */
}
.custom-card {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  padding: 10px 15px;
}
.header {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
}

.rbc-overlay {
  z-index: 10000 !important; /* Adjust this value based on your needs */
}

.form-select-rtl {
  direction: rtl; /* Ensure text and layout flow right to left */
  padding-right: 1.5rem; /* Ensure padding on the right for the text */
  padding-left: 0.75rem; /* Adjust left padding to make space for the arrow */
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23000" d="M2 0L0 2h4L2 0zm0 5L0 3h4l-2 2z"/></svg>')
    no-repeat;
  background-position: left 0.75rem center; /* Position the arrow on the left */
  background-size: 8px 10px;
}

#button2 {
  height: 50px;
  width: 200px;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid #252525;
  overflow: hidden;
  border-radius: 30px;
  color: #333;
  transition: all 0.5s ease-in-out;
}

.btn-txt {
  z-index: 1;
  font-weight: 800;
  letter-spacing: 4px;
}

.type1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  background-color: #333;
  border-radius: 30px;
  visibility: hidden;
  height: 10px;
  width: 10px;
  z-index: -1;
}

#button2:hover {
  box-shadow: 1px 1px 200px #252525;
  color: #fff;
  border: none;
}

.type1:hover::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}

.button4 {
  background-color: #f0f0f0; /* Light grey background */
  color: #000000; /* Black text */
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button4.active {
  background-color: #007bff; /* Blue background for active button */
  color: #ffffff; /* White text for active button */
}

.rbc-day-slot .rbc-events-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rbc-time-slot {
  min-height: 50px !important;
}

.rbc-day-slot .rbc-event {
  position: static;
  display: inline-block;
  vertical-align: top;
}

.host-button {
  visibility: hidden;
}
.participant-card:hover .host-button {
  visibility: visible;
}

.draggable-modal {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.no-select {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

@keyframes modalOpen {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalClose {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.draggable-modal-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
}

.draggable-modal-content {
  transition: 0.1s ease-in-out;
}

.draggable-modal-visible {
  visibility: visible;
  opacity: 1;
}

.draggable-modal-collapsed {
  visibility: collapse;
}

.draggable-modal-collapsed > div {
  top: 0%;
  right: -100%;
}

.rbc-button-link {
  font-size: 12px;
}

.appointment-card-date {
  background: #f3f3f3;
  border-radius: 50px;
}

.appointment-card-date:hover {
  background: #e5e5e5;
  border-radius: 50px;
}
.custom-checkbox {
  input[type="checkbox"] {
    border-radius: 2px;
    accent-color: var(
      --checkbox-color,
      rgb(179, 176, 176)
    ); // Fallback to red if not set
    border: 3px solid var(--checkbox-border-color, rgb(179, 178, 178)); // Border color fallback to red

    &:checked {
      background-color: var(
        --checkbox-background-color,
        rgb(179, 176, 176)
      ); // Background color fallback to red
    }
  }
}

.react-kanban-column {
  max-height: 350px;
  width: 240px;
  overflow-y: auto;
  background-color: #f1f2f4;
  padding: 5px;
}

.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}

.max-limit-input-field {
  max-height: 18px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 12px;
  width: 40px;
}

.ripple-container {
  position: relative;
  width: 100px; // Adjust size as needed
  height: 100px; // Adjust size as needed
  overflow: hidden;
  border-radius: 50%;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 1s infinite;
}

.prosparo-contract-modal {
  min-width: 90vw;
  height: 100%;
}

.rtl-editor .ql-editor {
  direction: rtl;
  text-align: right;
}

.ltr-editor .ql-editor {
  direction: ltr;
  text-align: left;
}

@keyframes ripple-animation {
  0% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(4);
    opacity: 1;
  }
}

.accordion {
  --bs-accordion-active-bg: #feecc90e !important;
  --bs-accordion-active-bg: map-get($theme-colors, primary-light) !important;
  --bs-accordion-btn-focus-box-shadow: 3px solid
    var(--checkbox-border-color, rgb(108, 107, 107)) !important ;
}

.tooltip-success .tooltip-inner {
  background-color: #28a745 !important;
}

.tooltip-danger .tooltip-inner {
  background-color: #dc3545 !important;
}

.modal-dialog.modal-xl.modal-fullscreen {
  padding: 16px;
}

.accordion-button:hover {
  z-index: 1 !important;
}

@import "./components/common/SlidingSideBar/bar";
@import "~bootstrap/scss/bootstrap";
