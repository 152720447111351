.custom-shining-gold {
  padding: 1px 0;
  color: gold;
  text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold;
  animation: shine 1.5s ease-in-out infinite alternate;
}

@keyframes shine {
  0% {
    transform: scale(0.9) rotate(-3deg);
    text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold;
  }

  50% {
    transform: scale(1.1) rotate(3deg);
    text-shadow: 0 0 30px gold, 0 0 50px gold, 0 0 80px gold;
  }

  100% {
    transform: scale(1) rotate(0deg);
    text-shadow: 0 0 20px gold, 0 0 40px gold, 0 0 60px gold;
  }
}
